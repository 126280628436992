// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-teal-50 via-cyan-50 to-teal-50 shadow-sm py-3 mt-auto">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link 
          to="/" 
          className="flex items-center gap-2 group"
        >
          <svg 
            className="w-5 h-5 text-slate-500 group-hover:text-slate-600 transition-colors duration-300"
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
          </svg>
          <span className="text-sm text-slate-600 group-hover:text-slate-800 transition-colors duration-300">
            Curso de Biología
          </span>
        </Link>
        
        <div className="text-sm text-slate-500 hover:text-slate-600 transition-colors duration-300 cursor-pointer">
          © 2024 FEC Vedruna Pamplona
        </div>
      </div>
    </footer>
  );
}

export default Footer;
