// src/components/Activity/PreguntasCompetenciales.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HelpCircle, ArrowLeft, MessageCircle } from 'lucide-react';

function PreguntasCompetenciales({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);

  if (!tema) {
    return (
      <div className="text-red-500">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const preguntas = tema.actividades.preguntas_competenciales;

  if (!preguntas || preguntas.length === 0) {
    return <div className="text-gray-500">No hay preguntas competenciales disponibles.</div>;
  }

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow p-6">
      <div className="mb-4">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones para las Preguntas Competenciales
        </h3>
      </div>
      <div className="pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>Serás redirigido a un chat interactivo con preguntas competenciales.</li>
          <li>Responde las preguntas siguiendo las instrucciones del chat.</li>
          <li>Recibirás retroalimentación inmediata sobre tus respuestas.</li>
          <li>Puedes hacer preguntas adicionales para aclarar conceptos.</li>
          <li>Al finalizar, regresa a esta página para continuar con otras actividades.</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones && renderInstrucciones()}

      <div className="rounded-xl border bg-white shadow hover:shadow-lg transition-shadow duration-300">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-teal-700"></h3>
        </div>
        <div className="p-6 pt-0">
          <div className="flex items-center justify-center mb-4">
            <img src="/assets/imagenes/Preguntas4.png" alt="Preguntas Competenciales" className="w-32 h-32 object-cover rounded-full" />
          </div>
          <div className="text-center">
            <h3 className="text-2xl font-bold text-teal-700 mb-4">Preguntas Competenciales</h3>
            <p className="text-lg mb-6">Haz clic en el botón para comenzar con las preguntas competenciales</p>
            <a
              href={preguntas[0].enlace}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 px-6 py-3 text-sm sm:text-base whitespace-nowrap"
            >
              <MessageCircle className="mr-2" />
              <span className="sm:inline">Comenzar Preguntas Competenciales</span>
            </a>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>
    </div>
  );
}

PreguntasCompetenciales.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.shape({
        preguntas_competenciales: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            enlace: PropTypes.string.isRequired
          })
        )
      }).isRequired
    })
  ).isRequired
};

export default PreguntasCompetenciales;
