// src/components/Activity/VideosPodcast.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, X, ArrowLeft } from 'lucide-react';
import PropTypes from 'prop-types';

function VideosPodcast({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [recursoSeleccionado, setRecursoSeleccionado] = useState(null);

  if (!tema) {
    return (
      <div className="text-red-500">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const recursos = tema.actividades.videos_podcast;

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones para los Vídeos y Podcasts
        </h3>
      </div>
      <div className="p-6 pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>Estos recursos están diseñados para complementar tu aprendizaje sobre los conceptos clave de este tema.</li>
          <li>Puedes pausar, reiniciar o ajustar el volumen según tus necesidades.</li>
          <li>Toma notas mientras ves o escuchas para reforzar tu comprensión del tema.</li>
          <li>Puedes cerrar el recurso en cualquier momento para volver a la lista.</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  const renderRecursoSeleccionado = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full h-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold text-teal-700">{recursoSeleccionado.titulo}</h2>
          <button 
            onClick={() => setRecursoSeleccionado(null)}
            className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-grow p-4">
          {recursoSeleccionado.url.endsWith('.mp4') ? (
            <video
              src={recursoSeleccionado.url}
              className="w-full h-full"
              controls
              autoPlay
              playsInline
            />
          ) : (
            <audio
              src={recursoSeleccionado.url}
              className="w-full"
              controls
              autoPlay
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones && renderInstrucciones()}
      
      {recursos && recursos.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {recursos.map((recurso) => (
            <div key={recurso.id} className="rounded-xl border bg-white shadow hover:shadow-lg transition-shadow duration-300">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-lg font-semibold text-teal-700">{recurso.titulo}</h3>
              </div>
              <div className="p-6 pt-0 space-y-4">
                <p className="text-gray-600">{recurso.descripcion}</p>
                <button
                  onClick={() => setRecursoSeleccionado(recurso)}
                  className="w-full inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 hover:bg-teal-700 text-white h-9 px-4 py-2"
                >
                  {recurso.url.endsWith('.mp4') ? 'Ver Vídeo' : 'Escuchar Audio'}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-teal-600">No hay vídeos o podcasts disponibles para este tema.</p>
      )}

      {recursoSeleccionado && renderRecursoSeleccionado()}

      <div className="flex justify-end mt-6">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>
    </div>
  );
}

VideosPodcast.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.shape({
        videos_podcast: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            descripcion: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
          })
        )
      }).isRequired
    })
  ).isRequired
};

export default VideosPodcast;
