// src/components/Activity/MapasMentales.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, X, ArrowLeft, Eye, ExternalLink } from 'lucide-react';
import PropTypes from 'prop-types';

function MapasMentales({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [mapaSeleccionado, setMapaSeleccionado] = useState(null);

  if (!tema) {
    return (
      <div className="text-red-500">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const mapas = tema.actividades.mapas_mentales;

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones para los Mapas Conceptuales
        </h3>
      </div>
      <div className="p-6 pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>Estos mapas conceptuales interactivos están diseñados para ayudarte a repasar los contenidos fundamentales de este tema específico.</li>
          <li>Tienes dos opciones para ver cada mapa mental:</li>
          <ul className="list-disc list-inside ml-6 space-y-1">
            <li>"Ver Incrustado": Abre el mapa directamente en esta página.</li>
            <li>"Abrir en Nueva Pestaña": Abre el mapa en una nueva pestaña del navegador.</li>
          </ul>
          <li>Puedes hacer zoom para acercar o alejar el mapa mental, y moverlo libremente.</li>
          <li>Pulsa en cualquiera de los nodos circulares para colapsar o desplegar los niveles inferiores del mapa.</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  const renderMapaSeleccionado = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full h-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold text-teal-700">{mapaSeleccionado.titulo}</h2>
          <button 
            onClick={() => setMapaSeleccionado(null)}
            className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-grow">
          <iframe
            src={`${process.env.PUBLIC_URL}${mapaSeleccionado.url}`}
            title={mapaSeleccionado.titulo}
            className="w-full h-full border-0"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones && renderInstrucciones()}

      {mapas && mapas.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {mapas.map((mapa) => (
            <div key={mapa.id} className="rounded-xl border bg-white shadow hover:shadow-lg transition-shadow duration-300">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-lg font-semibold text-teal-700">{mapa.titulo}</h3>
                <p className="text-sm text-gray-600">{mapa.descripcion}</p>
              </div>
              <div className="p-6 pt-0 space-y-2">
                <button
                  onClick={() => setMapaSeleccionado(mapa)}
                  className="w-full inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 hover:bg-teal-700 text-white h-9 px-4 py-2"
                >
                  <Eye className="mr-2 h-4 w-4" /> Ver Incrustado
                </button>
                <button
                  onClick={() => window.open(`${process.env.PUBLIC_URL}${mapa.url}`, '_blank')}
                  className="w-full inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-500 hover:bg-teal-600 text-white h-9 px-4 py-2"
                >
                  <ExternalLink className="mr-2 h-4 w-4" /> Abrir en Nueva Pestaña
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-teal-600">No hay mapas conceptuales disponibles para este tema.</p>
      )}

      {mapaSeleccionado && renderMapaSeleccionado()}

      <div className="flex justify-end mt-6">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>
    </div>
  );
}

MapasMentales.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.shape({
        mapas_mentales: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            descripcion: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
          })
        )
      }).isRequired
    })
  ).isRequired
};

export default MapasMentales;
