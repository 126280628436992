// src/components/Breadcrumbs.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function Breadcrumbs({ temas }) {
  const location = useLocation();
  const { pathname } = location;

  // Dividir el pathname en segmentos
  const pathSegments = pathname.split('/').filter(seg => seg !== '');

  // Mapeo de actividadId a títulos legibles
  const actividadTitles = {
    test: 'Test de Autoevaluación',
    flashcards: 'Flashcards',
    videos_podcast: 'Vídeos y Podcasts',
    tutor_virtual: 'Tutor Virtual',
    preguntas_competenciales: 'Preguntas Competenciales',
    clasificacion: 'Ejercicios de Clasificación',
    mapas_mentales: 'Mapas Mentales'
  };

  const breadcrumbItems = [];

  // Agregar el botón de Home
  breadcrumbItems.push(
    <li key="home" className="inline-flex items-center">
      <Link 
        to="/" 
        className="flex items-center gap-2 px-4 py-1.5 rounded-l-full bg-teal-50 border border-teal-100 shadow-sm hover:scale-105 transition-transform duration-300 will-change-transform"
        style={{ border: '1px solid #A7F3D0' }}
      >
        <svg
          className="w-4 h-4 text-slate-700"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span className="text-slate-700 font-medium">Home</span>
      </Link>
    </li>
  );

  // Verificar si la ruta contiene al menos dos segmentos: '/tema/:id'
  if (pathSegments.length >= 2) {
    const temaId = pathSegments[1];
    const tema = temas.find(t => t.id === parseInt(temaId));

    if (tema) {
      breadcrumbItems.push(
        <li key="tema" className="inline-flex items-center">
          <span className="mx-2 text-gray-400">/</span>
          <Link to={`/tema/${tema.id}`} className="flex items-center gap-2 px-4 py-1.5 rounded-full bg-teal-50 border border-teal-100 shadow-sm hover:scale-105 transition-transform duration-300 will-change-transform text-slate-700 font-medium"
            style={{ border: '1px solid #A7F3D0' }}
          >
            <span>{`TEMA ${temaId}`}</span>
          </Link>
        </li>
      );
    }
  }

  // Verificar si la ruta contiene al menos tres segmentos: '/tema/:id/:actividadId'
  if (pathSegments.length >= 3) {
    const actividadId = pathSegments[2];
    const actividadTitle = actividadTitles[actividadId] || actividadId;

    breadcrumbItems.push(
      <li key="actividad" className="inline-flex items-center">
        <span className="mx-2 text-gray-400">/</span>
        <Link to={`/tema/${pathSegments[1]}/${actividadId}`} className="flex items-center gap-2 px-4 py-1.5 rounded-full bg-teal-50 border border-teal-100 shadow-sm hover:scale-105 transition-transform duration-300 will-change-transform text-slate-700 font-medium"
          style={{ border: '1px solid #A7F3D0' }}
        >
          <span className="text-gray-500">{actividadTitle}</span>
        </Link>
      </li>
    );
  }

  return (
    <nav className="text-sm mb-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {breadcrumbItems}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.object.isRequired
    })
  ).isRequired
};

export default Breadcrumbs;
