import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import { CheckCircle, XCircle, HelpCircle, Settings, ArrowLeft } from 'lucide-react';

function TestAutoevaluacion({ temas }) {
  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));

  const [numQuestions, setNumQuestions] = useState(5);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [testStarted, setTestStarted] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [mostrarConfeti, setMostrarConfeti] = useState(false);
  const [dimensionesVentana, setDimensionesVentana] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDimensionesVentana({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const shuffleArray = (array) => {
    return [...array].sort(() => 0.5 - Math.random());
  };

  const initializeTest = useCallback(() => {
    if (tema && tema.actividades.test?.[0]?.preguntas) {
      const allQuestions = tema.actividades.test[0].preguntas;
      const shuffled = shuffleArray(allQuestions).slice(0, numQuestions);
      setShuffledQuestions(shuffled.map(q => ({
        ...q,
        opciones: shuffleArray(q.opciones)
      })));
    }
  }, [tema, numQuestions]);

  useEffect(() => {
    if (testStarted) {
      initializeTest();
    }
  }, [testStarted, initializeTest]);

  const handleOptionClick = (option) => {
    const isCorrect = option.correcta;
    setUserAnswers([...userAnswers, {
      question: shuffledQuestions[currentQuestion].pregunta,
      userAnswer: option.respuesta,
      correct: isCorrect,
      correctAnswer: shuffledQuestions[currentQuestion].opciones.find(o => o.correcta).respuesta
    }]);

    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < shuffledQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      const finalScore = (score + (isCorrect ? 1 : 0)) / shuffledQuestions.length;
      if (finalScore > 0.9) {
        setMostrarConfeti(true);
      }
      setShowScore(true);
    }
  };

  const startTest = () => {
    setTestStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setUserAnswers([]);
    setMostrarConfeti(false);
  };

  // Verificar si hay preguntas disponibles
  const hasQuestions = tema?.actividades?.test?.[0]?.preguntas?.length > 0;

  useEffect(() => {
    if (!hasQuestions) {
      console.error('No hay preguntas disponibles para este tema');
      return;
    }
  }, [hasQuestions]);

  if (!tema || !hasQuestions) {
    return (
      <div className="p-6 border rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            No hay preguntas disponibles
          </h2>
          <p className="text-gray-600">
            Lo sentimos, no hay preguntas disponibles para este tema en este momento.
          </p>
          <Link 
            to={`/tema/${id}`}
            className="mt-6 inline-block py-2 px-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors"
          >
            Volver al tema
          </Link>
        </div>
      </div>
    );
  }

  if (!testStarted) {
    return (
      <div className="p-6 border rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
        <div className="flex items-center mb-6">
          <HelpCircle className="w-6 h-6 text-teal-600 mr-2" />
          <h2 className="text-2xl font-bold text-gray-800">Instrucciones del Test</h2>
        </div>
        
        <ol className="list-decimal list-inside space-y-3 text-gray-600 mb-8">
          <li>Se te presentarán preguntas de opción múltiple.</li>
          <li>Lee cada pregunta y selecciona la respuesta que consideres correcta.</li>
          <li>Una vez seleccionada una respuesta, pasarás automáticamente a la siguiente.</li>
          <li>No podrás volver a las preguntas anteriores, así que elige con cuidado.</li>
          <li>Al final del test, verás tu puntuación y podrás revisar tus respuestas.</li>
        </ol>
        
        <div className="mb-8 p-6 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center mb-4">
            <Settings className="w-5 h-5 text-teal-600 mr-2" />
            <h3 className="text-lg font-semibold text-gray-800">Configura tu test</h3>
          </div>
          <label className="block mb-2 text-gray-700">Número de preguntas: {numQuestions}</label>
          <input
            type="range"
            min="5"
            max="25"
            value={numQuestions}
            onChange={(e) => setNumQuestions(parseInt(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-teal-600"
          />
        </div>

        <button
          onClick={startTest}
          className="w-full py-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 
                   transition-colors duration-300 text-lg font-semibold shadow-md
                   hover:shadow-lg transform hover:-translate-y-0.5"
        >
          Comenzar Test
        </button>
      </div>
    );
  }

  if (showScore) {
    return (
      <div className="p-6 border rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
        {mostrarConfeti && (
          <Confetti
            width={dimensionesVentana.width}
            height={dimensionesVentana.height}
            recycle={false}
            numberOfPieces={200}
          />
        )}
        
        <div className="flex justify-end mb-6">
          <div className="flex flex-col sm:flex-row gap-2">
            <button
              onClick={startTest}
              className="px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 
                       transition-colors duration-300 font-semibold shadow-md
                       hover:shadow-lg transform hover:-translate-y-0.5"
            >
              Reiniciar Test
            </button>
            <Link 
              to={`/tema/${id}`}
              className="px-4 py-2 border border-teal-600 text-teal-600 rounded-lg
                       hover:bg-teal-50 transition-colors duration-300 font-semibold
                       flex items-center justify-center gap-2"
            >
              <ArrowLeft className="w-4 h-4" /> Volver a Actividades
            </Link>
          </div>
        </div>
        
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">
            {score / shuffledQuestions.length > 0.9 
              ? "¡Felicidades! Has obtenido un resultado excelente."
              : "Has completado el test."}
          </h2>
          <p className="text-xl mb-6 text-gray-700">
            Has acertado <span className="font-bold text-teal-600">{score}</span> de{' '}
            <span className="font-bold text-teal-600">{shuffledQuestions.length}</span> preguntas
          </p>
        </div>

        <div className="space-y-4 mb-8">
          {userAnswers.map((answer, index) => (
            <div key={index} className="p-4 border rounded-lg bg-white shadow-sm">
              <div className="flex items-center gap-2 mb-2">
                {answer.correct ? (
                  <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
                ) : (
                  <XCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
                )}
                <h3 className="font-semibold text-gray-800">{answer.question}</h3>
              </div>
              <div className="ml-8 space-y-2">
                <p className={`${answer.correct ? 'text-green-600' : 'text-red-600'}`}>
                  Tu respuesta: {answer.userAnswer}
                </p>
                {!answer.correct && (
                  <p className="text-green-600 font-medium">
                    Respuesta correcta: {answer.correctAnswer}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <button
            onClick={startTest}
            className="px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 
                     transition-colors duration-300 text-lg font-semibold shadow-md
                     hover:shadow-lg transform hover:-translate-y-0.5"
          >
            Reiniciar Test
          </button>
          <Link 
            to={`/tema/${id}`}
            className="px-6 py-3 border-2 border-teal-600 text-teal-600 rounded-lg
                     hover:bg-teal-50 transition-colors duration-300 text-lg font-semibold
                     flex items-center justify-center gap-2"
          >
            <ArrowLeft className="w-5 h-5" /> Volver a Actividades
          </Link>
        </div>
      </div>
    );
  }

  if (!shuffledQuestions.length) {
    return (
      <div className="p-6 border rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Cargando preguntas...
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 border rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <span className="font-semibold text-gray-700">
            Pregunta {currentQuestion + 1} de {shuffledQuestions.length}
          </span>
          <span className="text-teal-600 font-semibold">
            {Math.round((currentQuestion / shuffledQuestions.length) * 100)}%
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-teal-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${((currentQuestion + 1) / shuffledQuestions.length) * 100}%` }}
          ></div>
        </div>
      </div>

      {shuffledQuestions[currentQuestion] && (
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">
            {shuffledQuestions[currentQuestion].pregunta}
          </h3>
          <div className="space-y-3">
            {shuffledQuestions[currentQuestion].opciones.map((opcion, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(opcion)}
                className="w-full p-4 text-left rounded-lg bg-white hover:bg-teal-50 
                         border-2 border-gray-200 hover:border-teal-500 
                         transition-all duration-200 transform hover:scale-[1.01]
                         text-gray-700 font-medium shadow-sm"
              >
                {opcion.respuesta}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

TestAutoevaluacion.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.object.isRequired
    })
  ).isRequired
};

export default TestAutoevaluacion;
