// src/components/Activity/Clasificacion.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, ArrowLeft } from 'lucide-react';
import Confetti from 'react-confetti';
import useWindowSize from '../../hooks/useWindowSize';

function Clasificacion({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const dimensionesVentana = useWindowSize();
  const [modoSeleccionado, setModoSeleccionado] = useState(null);
  const [numeroConceptos, setNumeroConceptos] = useState(5);
  const [conceptosSeleccionados, setConceptosSeleccionados] = useState([]);
  const [conceptosAsignados, setConceptosAsignados] = useState({});
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [juegoIniciado, setJuegoIniciado] = useState(false);
  const [juegoTerminado, setJuegoTerminado] = useState(false);
  const [puntuacion, setPuntuacion] = useState(0);
  const [mostrarConfeti, setMostrarConfeti] = useState(false);
  const [resultados, setResultados] = useState({});

  const tema = temas?.find(t => t.id === Number(id));

  useEffect(() => {
    if (!modoSeleccionado || !tema?.actividades?.clasificacion?.modos) return;
    
    const modo = tema.actividades.clasificacion.modos.find(m => m.id === modoSeleccionado);
    if (modo) {
      const conceptosTotales = modo.conceptos;
      const seleccionados = [...conceptosTotales]
        .sort(() => Math.random() - 0.5)
        .slice(0, numeroConceptos);
      
      setConceptosSeleccionados(seleccionados);
      setConceptosAsignados({});
    }
  }, [modoSeleccionado, numeroConceptos, tema]);

  const handleDragStart = (e, concepto) => {
    e.dataTransfer.setData('conceptoId', concepto.id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, categoria) => {
    e.preventDefault();
    const conceptoId = e.dataTransfer.getData('conceptoId');
    
    setConceptosAsignados(prev => {
      const newState = { ...prev };
      
      // Eliminar el concepto de su categoría anterior
      Object.keys(newState).forEach(cat => {
        newState[cat] = newState[cat]?.filter(id => id !== conceptoId) || [];
      });
      
      // Añadir a la nueva categoría
      if (!newState[categoria]) {
        newState[categoria] = [];
      }
      newState[categoria].push(conceptoId);
      
      return newState;
    });
  };

  const verificarRespuestas = () => {
    const modo = tema.actividades.clasificacion.modos.find(m => m.id === modoSeleccionado);
    let aciertos = 0;
    let total = 0;
    const nuevosResultados = {};

    Object.entries(conceptosAsignados).forEach(([categoria, conceptosIds]) => {
      nuevosResultados[categoria] = {};
      conceptosIds.forEach(conceptoId => {
        const concepto = modo.conceptos.find(c => String(c.id) === conceptoId);
        const esCorrecta = concepto && concepto.categorias.includes(categoria);
        nuevosResultados[categoria][conceptoId] = esCorrecta;
        if (esCorrecta) aciertos++;
        total++;
      });
    });

    setResultados(nuevosResultados);
    const puntuacionFinal = Math.round((aciertos / total) * 100);
    setPuntuacion(puntuacionFinal);
    setJuegoTerminado(true);
    if (puntuacionFinal >= 80) {
      setMostrarConfeti(true);
    }
  };

  const renderJuego = () => {
    const modo = tema.actividades.clasificacion.modos.find(m => m.id === modoSeleccionado);
    
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Área de conceptos */}
          <div className="rounded-xl border bg-white shadow p-6">
            <h3 className="font-bold mb-4 text-teal-700">Conceptos</h3>
            <div className="space-y-2 min-h-[100px]">
              {conceptosSeleccionados.map(concepto => {
                const estaAsignado = Object.values(conceptosAsignados)
                  .some(ids => ids.includes(String(concepto.id)));
                
                if (estaAsignado) return null;

                return (
                  <div
                    key={concepto.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, concepto)}
                    className="p-3 rounded-lg shadow-sm cursor-grab bg-teal-50 border border-teal-200 hover:shadow-md"
                  >
                    {concepto.nombre}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Área de categorías */}
          <div className="space-y-4">
            <h3 className="font-bold text-teal-700">Categorías</h3>
            {modo.categorias.map(categoria => (
              <div
                key={categoria}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, categoria)}
                className="p-4 rounded-lg border-2 border-teal-200 bg-white"
              >
                <h4 className="font-semibold mb-2 text-teal-700">{categoria}</h4>
                <div className="min-h-[50px] space-y-2">
                  {conceptosAsignados[categoria]?.map(conceptoId => {
                    const concepto = conceptosSeleccionados.find(
                      c => String(c.id) === conceptoId
                    );
                    const esCorrecta = resultados[categoria]?.[conceptoId];
                    const mostrarResultado = juegoTerminado && resultados[categoria]?.[conceptoId] !== undefined;

                    return concepto ? (
                      <div
                        key={conceptoId}
                        className={`p-2 rounded border transition-colors ${
                          mostrarResultado
                            ? esCorrecta
                              ? 'bg-green-50 border-green-200 text-green-700'
                              : 'bg-red-50 border-red-200 text-red-700'
                            : 'bg-teal-50 border-teal-200'
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <span>{concepto.nombre}</span>
                          {mostrarResultado && (
                            <span className={`text-sm ${
                              esCorrecta ? 'text-green-600' : 'text-red-600'
                            }`}>
                              {esCorrecta ? '✓' : '✗'}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <button
            onClick={verificarRespuestas}
            disabled={juegoTerminado}
            className={`w-full inline-flex items-center justify-center rounded-md font-medium transition-colors h-9 px-4 py-2 ${
              juegoTerminado
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-teal-600 hover:bg-teal-700 text-white'
            }`}
          >
            {juegoTerminado ? 'Respuestas verificadas' : 'Verificar respuestas'}
          </button>

          {juegoTerminado && (
            <div className={`text-center p-4 rounded-lg ${
              puntuacion >= 80 ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
            }`}>
              <p className="font-bold text-lg">Puntuación: {puntuacion}%</p>
              <button
                onClick={() => {
                  setJuegoTerminado(false);
                  setResultados({});
                  setConceptosAsignados({});
                  setMostrarConfeti(false);
                }}
                className="mt-2 bg-teal-600 text-white px-4 py-2 rounded hover:bg-teal-700"
              >
                Intentar de nuevo
              </button>
            </div>
          )}
        </div>

        {mostrarConfeti && (
          <Confetti
            width={dimensionesVentana.width}
            height={dimensionesVentana.height}
            recycle={false}
            numberOfPieces={200}
          />
        )}
      </div>
    );
  };

  if (!tema || !tema.actividades?.clasificacion?.modos) {
    return (
      <div className="w-full max-w-4xl mx-auto p-4">
        <div className="flex justify-center">
          <Link 
            to={`/tema/${id}`}
            className="bg-teal-600 text-white px-4 py-2 rounded-lg hover:bg-teal-700"
          >
            Volver al tema
          </Link>
        </div>
      </div>
    );
  }

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow p-6">
      <div className="mb-4">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones para la Clasificación
        </h3>
      </div>
      <div className="pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>Selecciona un modo de clasificación para comenzar.</li>
          <li>Arrastra los conceptos hacia las categorías correspondientes.</li>
          <li>Un concepto puede pertenecer a múltiples categorías.</li>
          <li>Cuando hayas terminado, haz clic en "Verificar respuestas".</li>
          <li>¡Intenta conseguir la máxima puntuación!</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  const handleComenzarJuego = () => {
    if (!modoSeleccionado) return;
    setJuegoIniciado(true);
  };

  const renderSeleccionModo = () => (
    <div className="rounded-xl border bg-white shadow p-6">
      <h2 className="text-xl font-bold text-teal-700 mb-4">
        Selecciona el modo de clasificación
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {tema.actividades.clasificacion.modos.map(modo => (
          <button
            key={modo.id}
            onClick={() => setModoSeleccionado(modo.id)}
            className={`p-4 rounded-lg text-left transition-colors ${
              modoSeleccionado === modo.id 
                ? 'bg-teal-600 text-white' 
                : 'bg-teal-50 text-teal-700 hover:bg-teal-100'
            }`}
          >
            <h3 className="font-bold mb-2">{modo.titulo}</h3>
            <p className="text-sm opacity-90">{modo.descripcion}</p>
          </button>
        ))}
      </div>
      
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2 text-teal-700">
          Número de conceptos a clasificar
        </h3>
        <input
          type="range"
          min="5"
          max="10"
          value={numeroConceptos}
          onChange={(e) => setNumeroConceptos(Number(e.target.value))}
          className="w-full accent-teal-600"
        />
        <p className="text-center text-teal-600">{numeroConceptos} conceptos</p>
      </div>

      <button
        onClick={handleComenzarJuego}
        disabled={!modoSeleccionado}
        className="mt-6 w-full inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed h-9 px-4 py-2"
      >
        Comenzar
      </button>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones ? (
        renderInstrucciones()
      ) : juegoIniciado ? (
        renderJuego()
      ) : (
        renderSeleccionModo()
      )}

      <div className="flex justify-end mt-6">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>
    </div>
  );
}

export default Clasificacion;
