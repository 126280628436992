// src/components/Activity/Flashcards.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HelpCircle, ArrowLeft, ArrowRight, Lightbulb, Book, CheckCircle } from 'lucide-react';

// Componente Progress
const Progress = ({ value, max = 100, className = '' }) => {
  const percentage = Math.min(100, Math.max(0, (value / max) * 100));
  return (
    <div className={`w-full bg-teal-200 rounded-full h-2.5 ${className}`}>
      <div 
        className="bg-teal-500 h-2.5 rounded-full transition-all duration-300 ease-in-out"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

function Flashcards({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDefinicion, setShowDefinicion] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [reviewedCards, setReviewedCards] = useState(new Set());

  if (!tema || !tema.actividades.flashcards) {
    return (
      <div className="text-red-500">
        No hay flashcards disponibles.{' '}
        <Link to={`/tema/${id}`} className="text-blue-600 hover:underline">
          Volver al Tema
        </Link>
      </div>
    );
  }

  const flashcards = tema.actividades.flashcards;

  const flipCard = () => {
    setShowDefinicion(!showDefinicion);
    if (!showDefinicion) {
      setReviewedCards(prev => new Set(prev).add(currentIndex));
    }
  };

  const siguiente = () => {
    setShowDefinicion(false);
    setCurrentIndex((prev) => (prev + 1) % flashcards.length);
  };

  const anterior = () => {
    setShowDefinicion(false);
    setCurrentIndex((prev) => (prev - 1 + flashcards.length) % flashcards.length);
  };

  const renderInstructions = () => (
    <div className="mb-4 border-2 border-teal-300 bg-gradient-to-br from-teal-50 to-teal-100 shadow-lg rounded-lg p-6">
      <h2 className="flex items-center text-2xl font-bold text-teal-700 mb-4">
        <HelpCircle className="mr-2" />
        Instrucciones para las FlashCards
      </h2>
      <ol className="list-decimal list-inside space-y-3 text-teal-600">
        <li>Haz clic en la tarjeta para voltearla y ver la definición.</li>
        <li>Usa los botones de navegación para moverte entre las tarjetas.</li>
        <li>Tu progreso se guardará mientras repasas las tarjetas.</li>
      </ol>
      <button 
        onClick={() => setShowInstructions(false)} 
        className="mt-6 w-full sm:w-auto bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-md"
      >
        Comenzar el Repaso
      </button>
    </div>
  );

  const progressPercentage = (reviewedCards.size / flashcards.length) * 100;

  return (
    <div className="w-full max-w-3xl mx-auto p-6 bg-gradient-to-br from-teal-50 to-white rounded-xl shadow-2xl">
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-stretch sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Link 
          to={`/tema/${id}`}
          className="w-full text-left sm:w-auto border border-teal-400 text-teal-600 hover:bg-teal-100 
                   transition-all duration-300 transform hover:scale-105 shadow-sm py-2 px-4 rounded-lg
                   flex items-center justify-start"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver al Tema
        </Link>
        <div className="flex items-center bg-white px-4 py-2 rounded-full shadow-md w-full sm:w-auto justify-center">
          <Book className="text-teal-500 mr-2" size={20} />
          <span className="text-sm font-semibold text-teal-600">
            Tarjeta {currentIndex + 1} de {flashcards.length}
          </span>
        </div>
      </div>
      
      {showInstructions ? renderInstructions() : (
        <>
          <div className="mb-6 perspective">
            <div 
              className="w-full min-h-[24rem] cursor-pointer bg-gradient-to-br from-teal-50 to-teal-100 
                       shadow-2xl border-2 border-teal-300 hover:border-teal-500 transition-all duration-300 
                       transform hover:scale-[1.02] rounded-2xl overflow-hidden"
              onClick={flipCard}
            >
              <div className="h-full flex flex-col items-center justify-center p-8">
                <div className="w-full h-full text-center flex flex-col items-center justify-center">
                  {showDefinicion ? (
                    <CheckCircle className="text-teal-500 mb-6 flex-shrink-0" size={32} />
                  ) : (
                    <Lightbulb className="text-teal-500 mb-6 flex-shrink-0" size={32} />
                  )}
                  <h3 className="text-3xl font-bold text-teal-800 mb-6 leading-tight">
                    {showDefinicion ? flashcards[currentIndex].definicion : flashcards[currentIndex].concepto}
                  </h3>
                  <p className="text-teal-600 mt-auto bg-white bg-opacity-50 px-4 py-2 rounded-full text-sm font-medium">
                    {showDefinicion ? "Definición" : "Concepto"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <div className="flex justify-between mb-2 text-sm font-medium text-teal-600">
              <span>Progreso</span>
              <span>{Math.round(progressPercentage)}% Completado</span>
            </div>
            <Progress value={reviewedCards.size} max={flashcards.length} />
          </div>

          <div className="flex justify-between space-x-2">
            <button 
              onClick={anterior}
              className="w-1/2 py-3 border-2 border-teal-400 text-teal-600 rounded-lg
                       hover:bg-teal-100 transition-all duration-300 transform hover:scale-105 
                       shadow-sm flex items-center justify-center"
            >
              <ArrowLeft className="mr-2 h-4 w-4" /> Anterior
            </button>
            <button 
              onClick={siguiente}
              className="w-1/2 py-3 border-2 border-teal-400 text-teal-600 rounded-lg
                       hover:bg-teal-100 transition-all duration-300 transform hover:scale-105 
                       shadow-sm flex items-center justify-center"
            >
              Siguiente <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

Flashcards.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.shape({
        flashcards: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            concepto: PropTypes.string.isRequired,
            definicion: PropTypes.string.isRequired
          })
        )
      }).isRequired
    })
  ).isRequired
};

export default Flashcards;
