// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="bg-gradient-to-r from-teal-50 via-cyan-50 to-teal-50 shadow-sm py-3">
      <div className="container mx-auto px-4">
        <Link 
          to="/" 
          className="flex items-center gap-2 w-fit group"
        >
          <svg 
            className="w-6 h-6 text-slate-500 group-hover:text-slate-600 transition-colors duration-300"
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
          </svg>
          <span className="text-lg text-slate-700 font-medium group-hover:text-slate-900 transition-colors duration-300">
            Curso de Biología
          </span>
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
