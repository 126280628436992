// src/App.jsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Home from './pages/Home';
import Tema from './pages/Tema';
import TestAutoevaluacion from './components/Activity/TestAutoevaluacion';
import Flashcards from './components/Activity/Flashcards';
import VideosPodcast from './components/Activity/VideosPodcast';
import TutorChatbot from './components/Activity/TutorChatbot';
import PreguntasCompetenciales from './components/Activity/PreguntasCompetenciales';
import Clasificacion from './components/Activity/Clasificacion';
import MapasMentales from './components/Activity/MapasMentales';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Breadcrumbs from './components/Breadcrumbs';
import SopaLetras from './components/Activity/SopaLetras';
import Ahorcado from './components/Activity/Ahorcado';
import Documentos from './components/Activity/Documentos';

// Componente wrapper para Clasificacion
function ClasificacionWrapper({ temas }) {
  const { id } = useParams();
  return <Clasificacion temas={temas} />;
}

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error cargando data.json:', error));
  }, []);

  if (!data) {
    return <div className="flex justify-center items-center h-screen">Cargando...</div>;
  }

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8">
          <Breadcrumbs temas={data.temas} />
          <Routes>
            <Route path="/" element={<Home temas={data.temas} />} />
            <Route path="/tema/:id" element={<Tema temas={data.temas} />} />
            {/* Rutas específicas para cada tipo de actividad */}
            <Route path="/tema/:id/test" element={<TestAutoevaluacion temas={data.temas} />} />
            <Route path="/tema/:id/flashcards" element={<Flashcards temas={data.temas} />} />
            <Route path="/tema/:id/videos_podcast" element={<VideosPodcast temas={data.temas} />} />
            <Route path="/tema/:id/tutor_virtual" element={<TutorChatbot temas={data.temas} />} />
            <Route path="/tema/:id/preguntas_competenciales" element={<PreguntasCompetenciales temas={data.temas} />} />
            <Route 
              path="/tema/:id/clasificacion" 
              element={<ClasificacionWrapper temas={data.temas} />} 
            />
            <Route path="/tema/:id/mapas_mentales" element={<MapasMentales temas={data.temas} />} />
            <Route path="/tema/:id/sopa_letras" element={<SopaLetras temas={data.temas} />} />
            <Route path="/tema/:id/ahorcado" element={<Ahorcado temas={data.temas} />} />
            <Route path="/tema/:id/documentos" element={<Documentos temas={data.temas} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
