// src/pages/Tema.jsx
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClipboardQuestion,
  faBookOpen,
  faVideo,
  faRobot,
  faChartPie,
  faDiagramProject,
  faQuestionCircle,
  faPuzzlePiece,
  faGamepad,
  faFileText
} from '@fortawesome/free-solid-svg-icons';

function Tema({ temas }) {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Se ejecuta solo cuando el componente se monta

  const tema = temas.find((t) => t.id === parseInt(id));

  if (!tema) {
    return (
      <div className="text-red-500 text-center mt-10">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const tiposActividades = {
    test: 'Test de autoevaluación',
    flashcards: 'Flashcards',
    videos_podcast: 'Vídeos y Podcasts',
    tutor_virtual: 'Tutor Virtual',
    clasificacion: 'Ejercicio de clasificación',
    mapas_mentales: 'Mapas mentales',
    preguntas_competenciales: 'Preguntas competenciales',
    sopa_letras: 'Sopa de Letras',
    ahorcado: 'Juego del Ahorcado'
  };

  const coloresActividades = ['bg-blue-100', 'bg-green-100', 'bg-yellow-100', 'bg-purple-100', 'bg-red-100', 'bg-indigo-100'];

  const materialesEstudio = {
    documentos: 'Documentos de teoría',
    mapas_mentales: tiposActividades.mapas_mentales,
    tutor_virtual: tiposActividades.tutor_virtual,
  };

  const materialesEvaluacion = {
    test: tiposActividades.test,
    flashcards: tiposActividades.flashcards,
    preguntas_competenciales: tiposActividades.preguntas_competenciales,
    clasificacion: tiposActividades.clasificacion,
    sopa_letras: tiposActividades.sopa_letras,
    ahorcado: tiposActividades.ahorcado
  };

  const renderActividades = () => {
    const actividadesRenderizadas = [];

    Object.entries(tema.actividades).forEach(([tipo, actividades], index) => {
      if (actividades && actividades.length > 0) {
        const titulo = tiposActividades[tipo] || tipo;
        const subtitulo = getSubtitulo(tipo);
        const icono = getIcono(tipo);

        actividadesRenderizadas.push(
          <Link
            key={tipo}
            to={`/tema/${tema.id}/${tipo}`}
            className={`group block p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 
              bg-gradient-to-br ${getGradientColor(index)} 
              transform hover:scale-105 hover:-translate-y-1 mb-4 
              border border-white/20 backdrop-blur-sm relative overflow-hidden`}
          >
            <div className="absolute inset-0 bg-white/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            <span className="absolute top-3 right-3 text-2xl transform group-hover:rotate-12 transition-transform duration-300">
              {icono}
            </span>
            <div className="relative z-10">
              <h3 className="text-xl font-bold text-gray-900 mb-2">{titulo}</h3>
              <p className="text-sm text-gray-700 font-medium">{subtitulo}</p>
            </div>
          </Link>
        );
      }
    });

    if (actividadesRenderizadas.length === 0) {
      return <div className="text-gray-500 text-center">No hay actividades disponibles para este tema.</div>;
    }

    return actividadesRenderizadas;
  };

  const getSubtitulo = (tipo) => {
    const subtitulos = {
      test: 'Evalúa tus conocimientos.',
      flashcards: 'Aprende conceptos clave.',
      videos_podcast: 'Mira y escucha contenido.',
      tutor_virtual: 'Asistencia personalizada.',
      clasificacion: 'Clasifica las moléculas.',
      mapas_mentales: 'Visualiza la información.',
      preguntas_competenciales: 'Responde preguntas clave.',
      sopa_letras: 'Encuentra palabras ocultas.',
      ahorcado: 'Adivina conceptos clave.',
      documentos: 'Material teórico de referencia.'
    };
    return subtitulos[tipo] || '';
  };

  const getIcono = (tipo) => {
    const iconos = {
      test: <FontAwesomeIcon icon={faClipboardQuestion} className="text-gray-700/80" />,
      flashcards: <FontAwesomeIcon icon={faBookOpen} className="text-gray-700/80" />,
      videos_podcast: <FontAwesomeIcon icon={faVideo} className="text-gray-700/80" />,
      tutor_virtual: <FontAwesomeIcon icon={faRobot} className="text-gray-700/80" />,
      clasificacion: <FontAwesomeIcon icon={faChartPie} className="text-gray-700/80" />,
      mapas_mentales: <FontAwesomeIcon icon={faDiagramProject} className="text-gray-700/80" />,
      preguntas_competenciales: <FontAwesomeIcon icon={faQuestionCircle} className="text-gray-700/80" />,
      sopa_letras: <FontAwesomeIcon icon={faPuzzlePiece} className="text-gray-700/80" />,
      ahorcado: <FontAwesomeIcon icon={faGamepad} className="text-gray-700/80" />,
      documentos: <FontAwesomeIcon icon={faFileText} className="text-gray-700/80" />
    };
    return iconos[tipo] || <FontAwesomeIcon icon={faQuestionCircle} className="text-gray-700/80" />;
  };

  const getGradientColor = (index, isStudyMaterial = false) => {
    if (isStudyMaterial) {
      const gradientesEstudio = [
        'from-gray-300 to-gray-400',
        'from-gray-300 to-gray-400',
        'from-gray-300 to-gray-400'
      ];
      return gradientesEstudio[index % gradientesEstudio.length];
    }

    const gradientesEvaluacion = [
      'from-blue-200 to-blue-300',
      'from-green-200 to-green-300',
      'from-purple-200 to-purple-300',
      'from-yellow-200 to-yellow-300',
      'from-red-200 to-red-300',
      'from-indigo-200 to-indigo-300'
    ];
    return gradientesEvaluacion[index % gradientesEvaluacion.length];
  };

  const getCardColor = (index) => {
    const colores = [
      'bg-blue-500',     // Azul como en Home
      'bg-green-500',    // Verde como en Home
      'bg-yellow-500',   // Amarillo como en Home
      'bg-red-500',      // Rojo como en Home
      'bg-purple-500',   // Púrpura como en Home
      'bg-pink-500'      // Rosa como en Home
    ];
    return colores[index % colores.length];
  };

  const renderTarjeta = (tipo, titulo, index, seccion) => {
    if (tipo === 'clasificacion') {
      if (!tema.actividades?.clasificacion) return null;
    } else if (tipo === 'ahorcado') {
      if (!tema.actividades.sopa_letras?.[0]?.palabras?.length) return null;
    } else if (tipo === 'documentos') {
      if (!tema.contenidos?.length) return null;
    } else if (!tema.actividades[tipo]?.length) {
      return null;
    }

    return (
      <Link
        key={tipo}
        to={`/tema/${tema.id}/${tipo}`}
        className={`group block p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 
          bg-gradient-to-br ${getGradientColor(index, seccion === 'estudio')} 
          transform hover:scale-105 hover:-translate-y-1 mb-4 
          border border-white/20 backdrop-blur-sm relative overflow-hidden`}
      >
        <div className="absolute inset-0 bg-white/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
        <span className="absolute top-3 right-3 text-2xl transform group-hover:rotate-12 transition-transform duration-300">
          {getIcono(tipo)}
        </span>
        <div className="relative z-10">
          <h3 className="text-xl font-bold text-gray-900 mb-2">{titulo}</h3>
          <p className="text-sm text-gray-700 font-medium">{getSubtitulo(tipo)}</p>
        </div>
      </Link>
    );
  };

  const getDescripcionTema = (temaId) => {
    switch(temaId) {
      case 1:
        return "Descubre la estructura y funcionamiento de la célula eucariota, sus orgánulos y procesos fundamentales. Aprenderás sobre el núcleo, las mitocondrias, el retículo endoplasmático y otros componentes esenciales que hacen posible la vida tal como la conocemos.";
      // Aquí puedes añadir más casos para otros temas
      default:
        return tema.descripcion;
    }
  };

  return (
    <div className="min-h-screen relative bg-white py-16 px-6 overflow-hidden">
      {/* Patrón de fondo decorativo */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 right-0 h-40 bg-gradient-to-b from-teal-50 to-transparent"></div>
        <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-teal-50 to-transparent"></div>
        <div className="absolute inset-y-0 left-0 w-40 bg-gradient-to-r from-teal-50 to-transparent"></div>
        <div className="absolute inset-y-0 right-0 w-40 bg-gradient-to-l from-teal-50 to-transparent"></div>
        <div className="absolute inset-0 opacity-[0.03]" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23115e59' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}></div>
      </div>

      <div className="max-w-6xl mx-auto relative z-10">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-extrabold pt-6 pb-4 text-gray-800 animate-fade-in leading-tight">
            {tema.titulo}
          </h1>
          <div className="max-w-3xl mx-auto mt-4 relative">
            <div className="absolute inset-0 bg-white/80 backdrop-blur-sm rounded-lg transform -skew-y-1 shadow-lg"></div>
            <div className="relative p-6 text-gray-600">
              <div className="absolute left-0 top-0 h-full w-1 bg-teal-500/40 rounded-full"></div>
              <p className="text-lg font-medium leading-relaxed">
                {getDescripcionTema(tema.id)}
              </p>
            </div>
          </div>
        </div>

        {/* Sección Materiales de Estudio */}
        <div className="mb-24 relative">
          <div className="absolute inset-0 bg-white/60 backdrop-blur-sm rounded-lg -m-6 p-6"></div>
          <h2 className="text-2xl font-extrabold mb-8 text-gray-800 relative">
            Materiales de Estudio
            <div className="mt-2 w-32 h-0.5 bg-teal-500/40 rounded-full"></div>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
            {Object.entries(materialesEstudio).map(([tipo, titulo], index) => (
              renderTarjeta(tipo, titulo, index, 'estudio')
            ))}
          </div>
        </div>

        {/* Sección Actividades de Evaluación */}
        <div className="mt-20 relative">
          <div className="absolute inset-0 bg-white/60 backdrop-blur-sm rounded-lg -m-6 p-6"></div>
          <h2 className="text-2xl font-extrabold mb-8 text-gray-800 relative">
            Actividades de Evaluación
            <div className="mt-2 w-32 h-0.5 bg-teal-500/40 rounded-full"></div>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
            {Object.entries(materialesEvaluacion).map(([tipo, titulo], index) => (
              renderTarjeta(tipo, titulo, index + 4, 'evaluacion')
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

Tema.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.object.isRequired
    })
  ).isRequired
};

export default Tema;
