import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, X, ArrowLeft, FileText } from 'lucide-react';
import PropTypes from 'prop-types';

function Documentos({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState(null);

  if (!tema) {
    return (
      <div className="text-red-500">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const documentos = tema.contenidos;

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Documentos de Teoría
        </h3>
      </div>
      <div className="p-6 pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>Aquí encontrarás documentos teóricos para profundizar en los conceptos del tema.</li>
          <li>Puedes descargar los PDF para estudiarlos sin conexión.</li>
          <li>Los documentos están organizados por conceptos clave.</li>
          <li>Utiliza estos recursos como material de referencia para tu estudio.</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  const renderDocumentoSeleccionado = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full h-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold text-teal-700">{documentoSeleccionado.titulo}</h2>
          <button 
            onClick={() => setDocumentoSeleccionado(null)}
            className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-grow p-4">
          <iframe
            src={documentoSeleccionado.url}
            className="w-full h-full"
            title={documentoSeleccionado.titulo}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones && renderInstrucciones()}
      
      {documentos && documentos.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {documentos.map((documento) => (
            <div key={documento.id} className="rounded-xl border bg-white shadow hover:shadow-lg transition-shadow duration-300">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-lg font-semibold text-teal-700">{documento.titulo}</h3>
              </div>
              <div className="p-6 pt-0 space-y-4">
                <p className="text-gray-600">{documento.descripcion}</p>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setDocumentoSeleccionado(documento)}
                    className="flex-1 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 hover:bg-teal-700 text-white h-9 px-4 py-2"
                  >
                    <FileText className="mr-2 h-4 w-4" /> Ver PDF
                  </button>
                  <a
                    href={documento.url}
                    download
                    className="flex-1 inline-flex items-center justify-center rounded-md font-medium transition-colors border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
                  >
                    Descargar
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-teal-600">No hay documentos disponibles para este tema.</p>
      )}

      {documentoSeleccionado && renderDocumentoSeleccionado()}
    </div>
  );
}

Documentos.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      contenidos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          titulo: PropTypes.string.isRequired,
          descripcion: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired
};

export default Documentos; 