// src/components/Activity/TutorChatbot.jsx
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, ArrowLeft, MessageCircle } from 'lucide-react';
import PropTypes from 'prop-types';

function TutorChatbot({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [mostrarInstrucciones, setMostrarInstrucciones] = React.useState(true);

  if (!tema) {
    return (
      <div className="text-red-500">
        Tema no encontrado.{' '}
        <Link to="/" className="text-blue-600 hover:underline">
          Volver al Inicio
        </Link>
      </div>
    );
  }

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow p-6">
      <div className="mb-4">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones para el Tutor Virtual
        </h3>
      </div>
      <div className="pt-0">
        <ul className="list-disc list-inside space-y-2 text-teal-600">
          <li>El Tutor Virtual es un chatbot especializado en los contenidos de este tema.</li>
          <li>Puede responder preguntas, aclarar conceptos y proporcionar ejemplos relevantes.</li>
          <li>Utiliza los documentos del curso como fuente principal de información.</li>
          <li>Ofrece preguntas de seguimiento para profundizar en los temas discutidos.</li>
          <li>Es una herramienta ideal para reforzar tu comprensión y resolver dudas específicas.</li>
        </ul>
        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-4 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Entendido
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones && renderInstrucciones()}
      
      <div className="rounded-xl border bg-white shadow hover:shadow-lg transition-shadow duration-300">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-teal-700"></h3>
        </div>
        <div className="p-6 pt-0">
          <div className="flex items-center justify-center mb-4">
            <img src="/assets/imagenes/Tutor4.png" alt="Tutor Virtual" className="w-32 h-32 object-cover rounded-full" />
          </div>
          {tema.actividades.tutor_virtual.map((tutor) => (
            <div key={tutor.id} className="flex justify-center">
              <button
                onClick={() => window.open(tutor.enlace, '_blank')}
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 px-6 py-3 text-sm sm:text-base whitespace-nowrap"
              >
                <MessageCircle className="mr-2" />
                <span className="sm:inline">Iniciar chat con el Tutor Virtual</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button 
          onClick={() => window.history.back()}
          className="w-full sm:w-auto transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium bg-transparent border border-teal-600 text-teal-600 hover:bg-teal-50 h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>
    </div>
  );
}

TutorChatbot.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      actividades: PropTypes.shape({
        tutor_virtual: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            enlace: PropTypes.string.isRequired
          })
        )
      }).isRequired
    })
  ).isRequired
};

export default TutorChatbot;
