import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, ArrowLeft, SlidersHorizontal } from 'lucide-react';
import PropTypes from 'prop-types';

function SopaLetras({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [palabras, setPalabras] = useState([]);
  const [pistas, setPistas] = useState([]);
  const [grid, setGrid] = useState([]);
  const [encontradas, setEncontradas] = useState([]);
  const [completado, setCompletado] = useState(false);
  const [seleccion, setSeleccion] = useState({ inicio: null, fin: null });
  const [seleccionActual, setSeleccionActual] = useState([]);
  const [pistasVisibles, setPistasVisibles] = useState({});
  const [celdasEncontradas, setCeldasEncontradas] = useState([]);
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [numPalabras, setNumPalabras] = useState(8);

  const generarSopaDeLetras = useCallback((palabrasData) => {
    const size = 15;
    let grid = Array(size).fill().map(() => Array(size).fill(''));
    
    palabrasData.forEach(({ palabra }) => {
      let colocada = false;
      let intentos = 0;
      while (!colocada && intentos < 100) {
        const direccion = Math.floor(Math.random() * 8);
        const x = Math.floor(Math.random() * size);
        const y = Math.floor(Math.random() * size);

        if (puedoColocarPalabra(grid, palabra, x, y, direccion)) {
          colocarPalabra(grid, palabra, x, y, direccion);
          colocada = true;
        }
        intentos++;
      }
    });

    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        if (grid[i][j] === '') {
          grid[i][j] = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        }
      }
    }

    return grid;
  }, []);

  const puedoColocarPalabra = (grid, palabra, x, y, direccion) => {
    const size = grid.length;
    const [dx, dy] = [
      [1, 0], [0, 1], [1, 1], [-1, 0], [0, -1], [-1, -1], [1, -1], [-1, 1]
    ][direccion];

    if (x + dx * (palabra.length - 1) >= size || x + dx * (palabra.length - 1) < 0 ||
        y + dy * (palabra.length - 1) >= size || y + dy * (palabra.length - 1) < 0) return false;

    for (let i = 0; i < palabra.length; i++) {
      if (grid[x + dx * i][y + dy * i] !== '' && grid[x + dx * i][y + dy * i] !== palabra[i]) {
        return false;
      }
    }
    return true;
  };

  const colocarPalabra = (grid, palabra, x, y, direccion) => {
    const [dx, dy] = [
      [1, 0], [0, 1], [1, 1], [-1, 0], [0, -1], [-1, -1], [1, -1], [-1, 1]
    ][direccion];
    for (let i = 0; i < palabra.length; i++) {
      grid[x + dx * i][y + dy * i] = palabra[i];
    }
  };

  const obtenerPalabraSeleccionada = () => {
    return seleccionActual.map(coord => {
      const [x, y] = coord.split(',').map(Number);
      return grid[x][y];
    }).join('');
  };

  const mostrarPista = (index) => {
    setPistasVisibles(prev => ({...prev, [index]: true}));
  };

  const formatearPista = (palabra) => {
    return `${palabra[0]}${'-'.repeat(palabra.length - 2)}${palabra[palabra.length - 1]}`;
  };

  const handleMouseDown = (x, y) => {
    setSeleccion({ inicio: { x, y }, fin: { x, y } });
    setSeleccionActual([`${x},${y}`]);
  };

  const handleMouseEnter = (x, y) => {
    if (seleccion.inicio) {
      setSeleccion(prev => ({ ...prev, fin: { x, y } }));
      const nuevaSeleccion = obtenerCeldasSeleccionadas(
        seleccion.inicio.x,
        seleccion.inicio.y,
        x,
        y
      );
      setSeleccionActual(nuevaSeleccion);
    }
  };

  const handleMouseUp = () => {
    if (seleccion.inicio && seleccion.fin) {
      const palabraSeleccionada = obtenerPalabraSeleccionada();
      if (palabras.includes(palabraSeleccionada) && !encontradas.includes(palabraSeleccionada)) {
        setEncontradas(prev => [...prev, palabraSeleccionada]);
        setCeldasEncontradas(prev => [...prev, ...seleccionActual]);
        if (encontradas.length + 1 === palabras.length) {
          setCompletado(true);
        }
      }
    }
    setSeleccion({ inicio: null, fin: null });
    setSeleccionActual([]);
  };

  const obtenerCeldasSeleccionadas = (x1, y1, x2, y2) => {
    const celdas = [];
    const dx = Math.sign(x2 - x1);
    const dy = Math.sign(y2 - y1);
    const steps = Math.max(Math.abs(x2 - x1), Math.abs(y2 - y1));
    
    for (let i = 0; i <= steps; i++) {
      const x = x1 + dx * i;
      const y = y1 + dy * i;
      celdas.push(`${x},${y}`);
    }
    
    return celdas;
  };

  useEffect(() => {
    if (tema?.actividades?.sopa_letras?.[0]) {
      const sopaLetras = tema.actividades.sopa_letras[0];
      const palabrasData = sopaLetras.palabras;
      const palabrasLimitadas = palabrasData.slice(0, numPalabras);
      setPalabras(palabrasLimitadas.map(p => p.palabra));
      setPistas(palabrasLimitadas);
      setGrid(generarSopaDeLetras(palabrasLimitadas));
    }
  }, [tema, generarSopaDeLetras, numPalabras]);

  const renderInstrucciones = () => (
    <div className="mb-6 rounded-xl border bg-white shadow">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="flex items-center text-xl font-bold text-teal-700">
          <HelpCircle className="mr-2" />
          Instrucciones de la Sopa de Letras
        </h3>
      </div>
      <div className="p-6 pt-0">
        <ol className="list-decimal list-inside space-y-2 text-teal-600">
          <li>Busca las palabras ocultas en la cuadrícula de letras.</li>
          <li>Las palabras pueden estar en horizontal, vertical o diagonal.</li>
          <li>Haz clic y arrastra para seleccionar una palabra.</li>
          <li>Si la palabra es correcta, se marcará como encontrada.</li>
          <li>Puedes solicitar pistas si necesitas ayuda.</li>
        </ol>

        <div className="mt-8 p-4 bg-teal-50 rounded-lg">
          <div className="flex items-center gap-4">
            <SlidersHorizontal className="text-teal-600 h-5 w-5" />
            <div className="flex flex-col gap-2 flex-grow">
              <label className="font-medium text-teal-700">
                Selecciona el número de palabras a encontrar:
              </label>
              <div className="flex items-center gap-4">
                <input
                  type="range"
                  min="5"
                  max="15"
                  value={numPalabras}
                  onChange={(e) => setNumPalabras(Number(e.target.value))}
                  className="flex-grow"
                />
                <input
                  type="number"
                  min="5"
                  max="15"
                  value={numPalabras}
                  onChange={(e) => setNumPalabras(Number(e.target.value))}
                  className="w-16 px-2 py-1 border rounded bg-white"
                />
              </div>
            </div>
          </div>
        </div>

        <button 
          onClick={() => setMostrarInstrucciones(false)}
          className="mt-8 inline-flex items-center justify-center rounded-md font-medium transition-colors bg-teal-600 text-white hover:bg-teal-700 h-9 px-4 py-2"
        >
          Comenzar juego
        </button>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => window.history.back()}
          className="border border-teal-600 text-teal-600 hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-sm inline-flex items-center justify-center rounded-md font-medium h-9 px-4 py-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver
        </button>
      </div>

      {mostrarInstrucciones ? renderInstrucciones() : (
        <div className="bg-white rounded-xl shadow p-6">
          <h2 className="text-2xl font-bold text-teal-700 mb-6">
            Sopa de Letras: {tema.titulo}
          </h2>

          {completado ? (
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4">¡Felicidades! Has completado la Sopa de Letras</h3>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => {
                    setCompletado(false);
                    setEncontradas([]);
                    setGrid(generarSopaDeLetras(pistas));
                    setPistasVisibles({});
                    setCeldasEncontradas([]);
                  }}
                  className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700"
                >
                  Jugar de nuevo
                </button>
                <button
                  onClick={() => window.history.back()}
                  className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700"
                >
                  Volver a las actividades
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-6">
              <div className="space-y-4 mb-8">
                <h3 className="text-lg font-semibold text-teal-700 border-b pb-2">
                  Palabras a encontrar:
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {pistas.map(({ pista, palabra }, index) => (
                    <div
                      key={index}
                      className={`p-4 rounded-lg border ${
                        encontradas.includes(palabra) 
                          ? 'bg-green-50 border-green-200' 
                          : 'bg-gray-50 border-gray-200 hover:border-teal-200 transition-colors'
                      }`}
                    >
                      <div className="flex flex-col gap-2">
                        <div className="text-sm font-medium text-gray-600">{pista}</div>
                        {encontradas.includes(palabra) ? (
                          <div className="font-bold text-green-700">{palabra}</div>
                        ) : (
                          <div>
                            <button 
                              onClick={() => mostrarPista(index)}
                              className="text-sm text-teal-600 hover:text-teal-700 hover:underline focus:outline-none"
                            >
                              Ver pista
                            </button>
                            {pistasVisibles[index] && (
                              <div className="mt-2 font-mono text-sm text-teal-600 bg-teal-50 p-2 rounded">
                                {formatearPista(palabra)}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div 
                className="grid grid-cols-15 gap-0.5 w-full max-w-[600px] mx-auto"
                onMouseLeave={handleMouseUp}
                onTouchEnd={handleMouseUp}
              >
                {grid.map((row, x) => 
                  row.map((cell, y) => (
                    <button
                      key={`${x},${y}`}
                      className={`
                        w-8 h-8 flex items-center justify-center font-bold text-sm
                        ${celdasEncontradas.includes(`${x},${y}`) ? 'bg-green-200' :
                          seleccionActual.includes(`${x},${y}`) ? 'bg-yellow-200' : 'bg-white'}
                        border border-gray-300 rounded
                      `}
                      onMouseDown={() => handleMouseDown(x, y)}
                      onMouseEnter={() => handleMouseEnter(x, y)}
                      onMouseUp={handleMouseUp}
                      onTouchStart={() => handleMouseDown(x, y)}
                      onTouchMove={(e) => {
                        const touch = e.touches[0];
                        const element = document.elementFromPoint(touch.clientX, touch.clientY);
                        if (element?.id) {
                          const [x, y] = element.id.split(',').map(Number);
                          handleMouseEnter(x, y);
                        }
                      }}
                      onTouchEnd={handleMouseUp}
                      id={`${x},${y}`}
                    >
                      {cell}
                    </button>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SopaLetras.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      actividades: PropTypes.object.isRequired
    })
  ).isRequired
};

export default SopaLetras;