// src/pages/Home.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

function Home({ temas }) {
  if (!temas || temas.length === 0) {
    return (
      <>
        <Header />
        <div className="container mx-auto px-4 py-8">
          <div className="text-gray-500">No hay temas disponibles.</div>
        </div>
      </>
    );
  }

  const colores = ['bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-red-500', 'bg-purple-500', 'bg-pink-500'];

  return (
    <>
      <Header /> 
      <div className="container mx-auto px-4 py-8">
        <div className="relative mb-8">
          <img
            src="/assets/imagenes/Portada.webp"
            alt="Portada de Biología"
            className="w-full h-80 object-cover rounded-lg brightness-75"
          />
          <h1 className="absolute top-1/3 left-8 text-5xl font-bold text-white drop-shadow-lg">
            Biología 2º Bachiller
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {temas.map((tema) => (
            tema.visible ? (
              <Link
                key={tema.id}
                to={`/tema/${tema.id}`}
                className={`block p-8 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${colores[(tema.id - 1) % colores.length]} text-white relative overflow-hidden group`}
              >
                <img
                  src={tema.imagen}
                  alt={`Imagen de ${tema.titulo}`}
                  className="absolute inset-0 w-full h-full object-cover transition-opacity duration-300 opacity-0 group-hover:opacity-15"
                />
                <div className="relative z-10">
                  <h2 className="text-2xl font-extrabold mb-2 uppercase">{`TEMA ${tema.id}`}</h2>
                  <h3 className="text-lg font-semibold mb-2">{tema.titulo}</h3>
                </div>
              </Link>
            ) : (
              <div
                key={tema.id}
                className={`block p-8 rounded-lg shadow-md opacity-50 cursor-not-allowed ${colores[(tema.id - 1) % colores.length]} text-white relative overflow-hidden`}
              >
                <img
                  src={tema.imagen}
                  alt={`Imagen de ${tema.titulo}`}
                  className="absolute inset-0 w-full h-full object-cover opacity-0"
                />
                <div className="relative z-10">
                  <h2 className="text-2xl font-extrabold mb-2 uppercase">{`TEMA ${tema.id}`}</h2>
                  <h3 className="text-lg font-semibold mb-2">{tema.titulo}</h3>
                  <p className="text-sm italic">Próximamente disponible</p>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </>
  );
}

export default Home;
