import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HelpCircle, Lightbulb, ArrowLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';

const MAXIMO_INTENTOS = 6;
const VICTORIAS_PARA_CONFETI = 3;
const COSTO_PISTA = 4;

function Ahorcado({ temas }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const tema = temas.find((t) => t.id === parseInt(id));
  const [palabraActual, setPalabraActual] = useState({ palabra: '', pista: '' });
  const [letrasAdivinadas, setLetrasAdivinadas] = useState([]);
  const [intentosRestantes, setIntentosRestantes] = useState(MAXIMO_INTENTOS);
  const [juegoTerminado, setJuegoTerminado] = useState(false);
  const [victoria, setVictoria] = useState(false);
  const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
  const [mostrarConfeti, setMostrarConfeti] = useState(false);
  const [palabrasJugadas, setPalabrasJugadas] = useState(0);
  const [victoriasConsecutivas, setVictoriasConsecutivas] = useState(0);
  const [pistaMostrada, setPistaMostrada] = useState(false);
  const [confetiMostrado, setConfetiMostrado] = useState(false);

  const seleccionarNuevaPalabra = useCallback(() => {
    if (tema?.actividades?.sopa_letras?.[0]?.palabras) {
      const palabras = tema.actividades.sopa_letras[0].palabras;
      const palabraSeleccionada = palabras[Math.floor(Math.random() * palabras.length)];
      setPalabraActual({
        palabra: palabraSeleccionada.palabra.toUpperCase(),
        pista: palabraSeleccionada.pista
      });
      setLetrasAdivinadas([]);
      setJuegoTerminado(false);
      setVictoria(false);
      setPistaMostrada(false);
    }
  }, [tema]);

  useEffect(() => {
    if (!mostrarInstrucciones) {
      seleccionarNuevaPalabra();
      setIntentosRestantes(MAXIMO_INTENTOS);
    }
  }, [mostrarInstrucciones, seleccionarNuevaPalabra]);

  const normalizarLetra = (letra) => {
    return letra.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const manejarLetraSeleccionada = (letra) => {
    if (juegoTerminado || !palabraActual.palabra) return;

    const letraNormalizada = normalizarLetra(letra);
    if (!letrasAdivinadas.includes(letraNormalizada)) {
      const nuevasLetrasAdivinadas = [...letrasAdivinadas, letraNormalizada];
      setLetrasAdivinadas(nuevasLetrasAdivinadas);

      if (!normalizarLetra(palabraActual.palabra).includes(letraNormalizada)) {
        const nuevosIntentosRestantes = intentosRestantes - 1;
        setIntentosRestantes(nuevosIntentosRestantes);
        verificarEstadoJuego(nuevasLetrasAdivinadas, nuevosIntentosRestantes);
      } else {
        verificarEstadoJuego(nuevasLetrasAdivinadas, intentosRestantes);
      }
    }
  };

  const verificarEstadoJuego = (letrasAdivinadas, intentosRestantes) => {
    if (!palabraActual.palabra) return;

    const palabraNormalizada = normalizarLetra(palabraActual.palabra);
    const todasLetrasAdivinadas = palabraNormalizada.split('').every(letra => 
      letra === ' ' || letra === ',' || letrasAdivinadas.includes(letra)
    );

    if (todasLetrasAdivinadas) {
      setJuegoTerminado(true);
      setVictoria(true);
      const nuevasVictoriasConsecutivas = victoriasConsecutivas + 1;
      setVictoriasConsecutivas(nuevasVictoriasConsecutivas);
      setPalabrasJugadas(palabrasJugadas + 1);
      if (nuevasVictoriasConsecutivas >= VICTORIAS_PARA_CONFETI && !confetiMostrado) {
        setMostrarConfeti(true);
        setConfetiMostrado(true);
      }
    } else if (intentosRestantes === 0) {
      setJuegoTerminado(true);
      setVictoria(false);
      setVictoriasConsecutivas(0);
      setPalabrasJugadas(palabrasJugadas + 1);
    }
  };

  const continuarJugando = () => {
    seleccionarNuevaPalabra();
    setMostrarConfeti(false);
    setIntentosRestantes(MAXIMO_INTENTOS);
  };

  const resetearJuego = () => {
    setIntentosRestantes(MAXIMO_INTENTOS);
    setVictoriasConsecutivas(0);
    setPalabrasJugadas(0);
    setConfetiMostrado(false);
    seleccionarNuevaPalabra();
  };

  const mostrarPista = () => {
    if (!pistaMostrada && intentosRestantes > COSTO_PISTA) {
      setPistaMostrada(true);
      setIntentosRestantes(intentosRestantes - COSTO_PISTA);
    }
  };

  const renderPalabra = () => {
    if (!palabraActual.palabra) return null;
  
    return (
      <div className="flex flex-wrap justify-center max-w-full overflow-hidden">
        {palabraActual.palabra.split('').map((letra, index) => (
          <span key={index} className="text-lg sm:text-xl md:text-2xl font-bold mx-0.5 sm:mx-1 mb-1">
            {letra === ' ' ? '\u00A0' :
             letra === ',' ? ',' :
             letrasAdivinadas.includes(normalizarLetra(letra)) ? letra : '_'}
          </span>
        ))}
      </div>
    );
  };

  const renderTeclado = () => {
    const letras = 'abcdefghijklmnñopqrstuvwxyz'.split('');
    return (
      <div className="grid grid-cols-7 sm:grid-cols-9 gap-1 sm:gap-2 mt-4">
        {letras.map((letra) => (
          <button
            key={letra}
            onClick={() => manejarLetraSeleccionada(letra)}
            disabled={letrasAdivinadas.includes(normalizarLetra(letra)) || juegoTerminado}
            className={`w-8 h-8 sm:w-10 sm:h-10 text-sm sm:text-base font-semibold rounded-lg
              ${letrasAdivinadas.includes(normalizarLetra(letra))
                ? 'bg-gray-200 text-gray-400'
                : 'bg-white hover:bg-gray-100 text-gray-800 border border-gray-300'}
              disabled:cursor-not-allowed disabled:opacity-50`}
          >
            {letra}
          </button>
        ))}
      </div>
    );
  };

  const renderInstrucciones = () => (
    <div className="mb-4 relative">
      <div className="absolute top-4 right-4">
        <Link 
          to={`/tema/${id}`}
          className="border-teal-400 text-teal-600 hover:bg-teal-100 transition-all duration-300 transform hover:scale-105 shadow-sm py-2 px-4 rounded-md inline-flex items-center"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver a Actividades
        </Link>
      </div>
      <div className="rounded-xl border bg-white shadow">
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="flex items-center text-xl sm:text-2xl font-bold text-teal-700 mt-12">
            <HelpCircle className="mr-2" />
            Instrucciones del Ahorcado
          </h3>
        </div>
        <div className="p-6 pt-0">
          <ol className="list-decimal list-inside space-y-1 sm:space-y-2 text-sm sm:text-base text-teal-600">
            <li>Se seleccionará una palabra relacionada con el tema.</li>
            <li>Debes adivinar la palabra letra por letra.</li>
            <li>Tienes {MAXIMO_INTENTOS} intentos antes de perder.</li>
            <li>Puedes solicitar una pista por -{COSTO_PISTA} intentos.</li>
            <li>¡Consigue {VICTORIAS_PARA_CONFETI} victorias seguidas para una sorpresa!</li>
          </ol>
          <button 
            onClick={() => setMostrarInstrucciones(false)}
            className="mt-6 bg-teal-600 hover:bg-teal-700 text-white text-lg px-6 py-3 w-full sm:w-auto rounded-md"
          >
            Comenzar juego
          </button>
        </div>
      </div>
    </div>
  );

  if (mostrarInstrucciones) {
    return renderInstrucciones();
  }

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="flex justify-end mb-4">
        <Link 
          to={`/tema/${id}`}
          className="border-teal-400 text-teal-600 hover:bg-teal-100 transition-all duration-300 transform hover:scale-105 shadow-sm py-2 px-4 rounded-md inline-flex items-center"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Volver a Actividades
        </Link>
      </div>
      <div className="rounded-xl border bg-white shadow p-6">
        {mostrarConfeti && <Confetti recycle={false} numberOfPieces={200} />}
        <div className="flex flex-col space-y-1.5">
          <h2 className="text-xl sm:text-2xl font-bold text-teal-700">
            Juego del Ahorcado
          </h2>
        </div>
        <div className="text-center mb-4 sm:mb-6">
          <p className="text-base sm:text-lg mb-1 sm:mb-2">Intentos restantes: {intentosRestantes}</p>
          <p className="text-base sm:text-lg">Victorias consecutivas: {victoriasConsecutivas}</p>
        </div>

        <div className="text-center mb-4 sm:mb-6 px-2">
          {renderPalabra()}
        </div>

        {juegoTerminado ? (
          <div className="text-center">
            <h3 className={`text-xl sm:text-2xl font-bold ${victoria ? 'text-green-600' : 'text-red-600'} mb-2 sm:mb-4`}>
              {victoria ? '¡Has ganado!' : 'Has perdido'}
            </h3>
            <p className="text-lg sm:text-xl mb-2 sm:mb-4">
              La palabra era: <span className="font-bold">{palabraActual.palabra}</span>
            </p>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              Pista: {palabraActual.pista}
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2">
              <button 
                onClick={continuarJugando}
                className="w-full sm:w-auto bg-teal-600 hover:bg-teal-700 text-white text-base sm:text-lg px-4 sm:px-6 py-2 sm:py-3 rounded-md transform hover:scale-105 transition-transform"
              >
                Seguir jugando
              </button>
              <button 
                onClick={resetearJuego}
                className="w-full sm:w-auto bg-teal-500 hover:bg-teal-600 text-white rounded-md px-4 py-2"
              >
                Reiniciar juego
              </button>
              <Link 
                to={`/tema/${id}`}
                className="w-full sm:w-auto bg-gray-400 hover:bg-gray-500 text-white rounded-md px-4 py-2"
              >
                Volver a las actividades
              </Link>
            </div>
          </div>
        ) : (
          <>
            {renderTeclado()}
            <div className="mt-4 text-center">
              <button 
                onClick={mostrarPista}
                disabled={pistaMostrada || intentosRestantes <= COSTO_PISTA}
                className="w-full sm:w-auto bg-yellow-500 hover:bg-yellow-600 text-white text-sm sm:text-base rounded-md px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Lightbulb className="inline-block mr-2" />
                Pedir pista (-{COSTO_PISTA} intentos)
              </button>
              {pistaMostrada && (
                <p className="mt-2 text-sm sm:text-base text-teal-600">
                  Pista: {palabraActual.pista}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Ahorcado.propTypes = {
  temas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      actividades: PropTypes.object.isRequired
    })
  ).isRequired
};

export default Ahorcado;